import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../images/new-home-screenshot.png';
import SvgCharts from '../svg/SvgCharts';
import narrative1 from '../images/narrative-1.png';
import narrative2 from '../images/narr-detail-2.png';

export default () => (
<Layout>
<section className="pt-5 md:pt-5">
     <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-4/5">
          <h1 className="text-4xl lg:text-xl xl:text-2xl font-bold leading-none">
 			Visual Understanding Environment (VUE) as a Tool for the Construction of Narrative
 		  </h1>
 		  <p className="text-lg lg:text-xl mt-6 font-light">by Patrick J. Szucs Jr.</p>
		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 			Introduction
 		  </h2> 		  
		  <p className="text-lg lg:text-xl mt-6 font-light">I am an extremely visual person; the proposition or axiom that thought cannot exist without language is a proposition that is absurd. Visual Understanding Environment (VUE), a program that I have yet to discover the full potential of, has proven, for me, a useful tool in the translation of abstract non-symbolic concepts into symbolic, objective form; as importantly, it has proven useful in taming the nonlinear beast of thought by corralling it into the linear format of the written word. Conveying thought through writing is largely a task of organizing things linearly that have a non-linear nature; this organization creates the need for a narrative to be formed (this is true for both fiction and non-fiction), a narrative that usually is only one of many possible narratives. Of course, not all possible narratives are equal, and this is the rub: anyone can throw facts at a piece of paper, but to make those facts coherent in the most expedient and perhaps substantial way is the ultimate goal and mark of a well-written paper.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">I have found that VUE, for me, is the most intuitive way of organizing the linear narrative of a non-linear universe; this is how I have used VUE up until this time and what I used it for in the map that I presented on the featured map section of the VUE website. I will now explain the physical nature of this map, as well as its function as a tool for my process of constructing a coherent and expedient narrative of the emergence of a complex idea with complex origins.</p>		  
		  <img src={narrative1}/>
		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 		  Nodes
 		  </h2>
		 <p className="text-lg lg:text-xl mt-6 font-light">First and foremost, the node represents things, not necessarily tangible things but specific units, such as concepts, authors, events, or objects. A node is anything that can be conceived as a distinct unit. If a thing represented by a node begins to expand beyond the conception of a single unit, it will be split because concepts, or things, can often be viewed as: an aspect of a larger unit; a whole complete unit in itself; or the conflation of multiple units. A node is essentially equivalent to the sum of the nodes above it; in this way we can see how the furthest branches of the tree or map I have created are equivalent to the most base node. Another way of looking at this is that the base node is the entire paper while the tips of each branch are individual sentences in the paper. Moving a node from one place on the tree to another is equivalent to moving a sentence up or down in a Word document relative to the rest of the paper. Now this might sound confusing to a person who thinks in less visual ways, but for me turning sentences, or paragraphs, or sections into "physical things" (i.e. nodes) that I can arrange on a two-dimensional surface is a far more intuitive way of rearranging sentences or the narrative of a paper.</p>
		 <p className="text-lg lg:text-xl mt-6 font-light">Now that I have explained my conception of a node I will further divide up the two distinct uses of the node in my map: The first type is what is being written about, the subject of the paper; these nodes appear in a light tan color. The other type of node is the source node, representing the information that I am plugging into the paper and the place that the information has come from; these nodes are in dark red and often represent a particular writing, or my original ideas. Using these source nodes makes it very easy to keep track of where the information came from, which helps keep the attribution clear. I have found that my nodes often hop back and forth from source to subject, which can become confusing for myself and the reader. Dividing the node into two distinct types can help manage this back and forth in that I am visually aware of what I am doing with a piece of information or writing. But let's say that, while using a source (dark red node), I begin to talk about the source.... In such a case, the node would then be turned light tan because it has now taken on the role of subject in the paper. This distinction is very important, but often hard to see when sprawled across a piece of paper in words; thus the capabilities in VUE of nodes and the coloring of nodes helps me distinguish what I am writing about and what I am using as source for writing about it.</p>
		 <p className="text-lg lg:text-xl mt-6 font-light">Another useful aspect of the nodes in VUE is that one may embed a node into another; in this way I can identify an author or piece of writing, a link to a PDF, or even an image of an author, which I think helps me connect concepts to the real world. I also use the info window to click and paste the quote or statistic that I am using to discuss a subject; this helps me quickly see the original passage even if I don't use the exact wording in my writing.</p>
		  <img src={narrative2}/>
		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 		  Links
 		  </h2>
		  <p className="text-lg lg:text-xl mt-6 font-light">I use links in two ways: first, any sold link discusses the node that is directly above it on the tree; second, any node that is striped denotes that the information written in the higher link that it is attached to has been moved to the lower link that it is attached to; in this way I can combine disparate ideas into a single unit while maintaining the original separateness of the information. Striped links never attach to nodes, they simply convey the flow of information along the tree. Information may flow from the furthest branch down to the link below the base node, but this hasnâ€™t happened in any of my maps yet.</p>
          <p className="text-lg lg:text-xl mt-6 font-light">Color coding is vital to the links. For solid links (i.e., links that are attached to two different nodes at each end), red means that nothing is written into the link, green means that something is written, and blue means that the information in that link has been moved to a linear writing program, such a Word. Thus, there can never be a striped link transferring the information contained in a blue link to a lower link, because the information would then end up as a redundancy in the linear paper. For the striped links, if they are red, this conveys the intention to transfer information to a lower link; if these same links are black, the transfer has already taken place.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">Finally, the arrangement of the links and nodes is important for understanding how to read the map, or tree. Generally there is a base node; in this paper it is labeled "Responsibility to Protect." This is where the map begins if one wants to read it in a linear way. To read the map as it will appear in the linear paper, one reads moving clockwise from the link that is below a node while always moving up in the tree until reaching the end of a branch before moving back down and continuing to move clockwise.</p>
          <p className="text-lg lg:text-xl mt-6 font-light">The link notes are where the paper is written. Note that no information originates in links that are not attached to a source node; this means that I can always find the original moment of incorporation of an idea into the map.</p>
          <p className="text-lg lg:text-xl mt-6 font-light">It may seem cumbersome to have a paper split up into so many disparate links, but this actually facilitates an understanding of how each piece of information relates to the rest of the narrative, as well as facilitating an easy transfer of disparate ideas around the narrative, creating lots of opportunity to easily mold and morph the narrative as it begins to look like a coherent and expedient paper.</p>
 		  <h2 className="text-4xl lg:text-xl xl:text-xl mt-5 font-bold leading-none">
 		  Conclusion
 		  </h2>
          <p className="text-lg lg:text-xl mt-6 font-light">The logic of my map may seem confusing, but it keeps everything organized, and, further, creates a logical and intuitive way of turning non-linear information into a linear format without having to get lost in an endless line of sentences and letters. Through this logic and through this use of the VUE, the process of creating a narrative become as easy as clicking on a node or nodes and moving them to a new place. I think that it is fairly easy to see how the paragraphs of my paper are constructed and arranged. And the best part is that the clusters of nodes that became distinct units or paragraphs all started as a jumble of things, or nodes, or concepts that slowly and naturally separated and condensed into distinct units.</p>
		  <p className="text-lg lg:text-xl mt-6 font-light">This is something that obviously happens in a linear paper as well, but have you ever been able to look at an entire paper at the same moment? You can in this program. VUE makes it incredibly easy to see everything all at once, or only one thing at once, so that one does not need to hold the entire paper in one's head, nor does the author need to do what so many of us have done: continually reacquaint ourselves with the paper. VUE keeps the narrative fresh in the mind, leaving brain power for coming up with new ideas rather than remembering old ideas.</p>
          <p className="text-lg lg:text-xl mt-6 font-light">I'm not sure that I have incorporated every rule that I used for constructing this map, because the rules formed as I went and have not been codified until now, so if something doesn't make sense just shoot me an email. I'm certain that someone else will run much further with VUE in using it as a tool for constructing narrative more easily, and I hope that someone does because for someone like myself seeing makes all the difference.</p>
		  <p className="text-sm lg:text-sm mt-6 font-light">Patrick J. Szucs Jr. is an undergraduate of Political Science at California State University East Bay.</p>
 		</div>
    </div>
</section>
</Layout>
)